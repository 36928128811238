.label {
    font-size: 11px;
    text-transform: uppercase;
    color: #A49A9A;
}

.formTitle {
    color: #A49A9A;
}

.upload {
    border: 2px solid #000;
    color: #000;
    height: 100px;
    width: 250px;
    position: relative;
}

.dropImage {
    position: absolute;
    right: 1px;
    top: -20px;
    background-color: transparent;
    border: 0;
}

.uploadedImage {
    width: 100px;
    height: 100px;
    text-align: center;
}

.image {
    border-radius: 100px;
    width: 100px;
    height: 100px;
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.file {
    visibility: hidden;
    width: 1px;
}
