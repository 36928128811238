
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.base-app {
    background: rgb(23 23 23);
    color: #ffffff;
}

.base-landing {
    background-color: #2d3d4a;
}

.text-success {
    color: #044317;
}

.bordered-input {
    border: 1px solid #f78000;
}

.text-orange {
    color: #f78000;
}

.bg-banner {
    background-color: #009f60;
}

.text-banner {
    color: #009f60;
}


.text-app {
  color: #4a3023 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.App-header {
    border-bottom: 4px solid #f78000;
}

.spinner {
  animation: spin infinite 1s linear;

  /*You can increase or decrease the timer (5s) to
   increase or decrease the speed of the spinner*/
}

.badge {
  display: inline-block;
  width: 150px;
}

.small {
  font-size: 11px;
}

.Btn {
    background: #fff;
    color: #000;
    border: 1px solid #fff;
}

.Btn:focus {
    border: 1px solid #f1f1f1;
}

.ModalNavigation {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1500;
}

a {
    color: #000;
    text-decoration: none;
    display: block;
}

.text-red {
    background: red;
}

.CloseNavigation {
    position: absolute;
    right: 10px;
    background: transparent;
}

.grayscale {
    filter: grayscale(100%);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Logo {
    max-width: 100%;
}

.bg-image {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    background-color: #fff;
}
